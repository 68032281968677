import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import "../index.css";
import "../i18n";
import theme from "./theme";

const ProviderWrapper = ({ children, store, history }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <ConnectedRouter history={history}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            maxSnack={3}
          >
            <CssBaseline />
            {children}
          </SnackbarProvider>
        </ConnectedRouter>
      </CookiesProvider>
    </ThemeProvider>
  </Provider>
);

export default ProviderWrapper;
