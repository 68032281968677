import React from 'react';

import { useTranslation } from 'react-i18next';

import { AuthForm } from 'components/AuthForm/AuthForm';
import SignInForm from 'components/SignInForm/SignInForm';


const LoginPage = () => {
    const { t } = useTranslation();
    const handleLogin = () => {
        console.log("login")
    };

    return (
        <AuthForm
            isLogin={true}
            image={'https://app.weibook.co/assets/imgs/login.jpg'}
            title={t('LogIn.title')}
            logo={'https://d1itoeljuz09pk.cloudfront.net/recursos/logo.svg'}
        >
            <SignInForm onLogin={handleLogin} />
        </AuthForm>
    );
}


export default LoginPage;