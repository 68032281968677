import React from 'react'


//UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'

const Loading = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center">
            <CircularProgress />
        </Box>
    )
}

export default Loading
