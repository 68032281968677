import { Cookies } from "react-cookie";
import { loginService, signUpService } from "../services/auth";

export const LOGIN_STATE_TOGGLE = "auth/LOGIN_STATE_TOGGLE";
export const LOGIN = "auth/LOGIN";
export const SIGN_UP_STATE_TOGGLE = "auth/SIGN_UP_STATE_TOGGLE";
export const SIGN_UP = "auth/SIGN_UP";
export const LOGOUT = "auth/LOGOUT";
export const SAVE_SIGNUP = "auth/SAVE_SIGNUP";
export const CHANGE_VENUE_PROFILE = "auth/CHANGE_VENUE_PROFILE";
export const GET_INFORMATION_USER = "auth/GET_INFORMATION_USER";

const cookies = new Cookies();
const currentUser = cookies.get("user") || {};

const initialState = {
  isUserAuthenticated: cookies.get('user') ? true : false,
  userEmail: currentUser.userEmail,
  signUpData: {
    email: "",
    password: "",
  },
};

const authModule = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STATE_TOGGLE:
      return {
        ...state,
        ...action.payload,
        isLoggingIn: !state.isLoggingIn,
      };

    case LOGIN:
      return {
        ...state,
        ...action.payload,
        isUserAuthenticated: true,
        isLoggingIn: false,
      };

    case SIGN_UP_STATE_TOGGLE:
      return {
        ...state,
        isSigningUp: !state.isSigningUp,
        ...action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        ...action.payload,
        isUserAuthenticated: false,
        userEmail: null,
        userType: 0,
        userId: null,
        storeId: null,
      };

    case SAVE_SIGNUP:
      return {
        ...state,
        signUpData: action.payload,
      };

    case CHANGE_VENUE_PROFILE:
      return {
        ...state,
        ...action.payload,
      };

    case GET_INFORMATION_USER:
      return {
        ...state,
        permissions: action.payload,
      };

    default:
      return state;
  }
};

/**
 * Calls the loginService and executes the given callback
 * @param {string} email user email
 * @param {string} pass user password
 * @param {function} onLogin callback to run after login success
 * @param {function} onError callback to run after login error
 */
export const logIn = (email, pass, onLogin, onError) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_STATE_TOGGLE });
    loginService(email, pass)
      .then(({ data }) => {
        console.log('data',data)
        const user = {
          userEmail: data.email,
        };
        localStorage.setItem("token", data.token);
        cookies.set("user", user, { path: "/" });
        onLogin();
        dispatch({
          type: LOGIN,
          payload: { ...user },
        });
        dispatch({ type: SIGN_UP_STATE_TOGGLE, isSigningUp: false });
      })
      .catch((err) => {
        onError(err);
        dispatch({ type: LOGIN_STATE_TOGGLE });
      });
  };
};

/**
 * Logs the use in withou the api call
 */
export const logInDirectly = () => (dispatch) => {
  dispatch({ type: LOGIN });
};

/**
 * Creates a new user and then logs the new user in
 * @param {Object} signUpData sign up forms data
 */
export const signUp = (signUpData, onSignUp, onError) => {
  return (dispatch) => {
    dispatch({ type: SIGN_UP_STATE_TOGGLE });

    signUpService(signUpData)
      .then((res) => {
        if (res.data.error_code === 202) {
          onError("");
          dispatch({ type: SIGN_UP_STATE_TOGGLE });
        }
        if (res.status === 200) {
          onSignUp(
            signUpData.email,
            signUpData.password,
            () => false,
            () => false
          );
        }
      })
      .catch((err) => {
        onError(err);
        dispatch({ type: SIGN_UP_STATE_TOGGLE });
      });
  };
};

/**
 * Deletes the token cookie and logs out the user
 */
export const logOut = () => (dispatch) => {
  cookies.remove("user", { path: "/" });
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  dispatch({
    type: LOGOUT,
  });
};

/**
 * Saves the signUpForm state in the store
 * @param {Object} payload form data
 */
export const saveSignUpData = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_SIGNUP,
    payload,
  });
};

export default authModule;
