import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  isActive: {
    background: "#4ECDC4",
    color: "white",
  },
  isNotActive: {
    background: "white",
  },
  // in use
  avatarUSer: {
    color: "#3C3C3B",
    margin: "0px auto",
    alignSelf: "center",
    width: 38,
    height: 38,
    background: theme.themeColorSoft,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.2rem",
    objectFit: "cover",
  },
  cardDetail: {
    background: "#ffffff",
    padding: "1.5rem 2rem",
    borderRadius: "20px",
    position: "relative",
  },
  cardDetailRef: {
    width: "85%",
    height: "2.6rem",
    display: "flex",
    marginBottom: "1rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    background: "#73baa9",
    color: "white",
  },
  //   buttons
  button: {
    width: "130px",
    height: "50px",
    background: theme.themeColor,
    color: "white",
  },
  button2: {
    height: "50px",
    background: theme.themeColorSoft,
    color: "black",
    marginBottom: "20px",
    width: "100%",
  },
  buttonStrong: {
    width: "170px",
    height: "50px",
    background: theme.themeColorStrong,
    color: "#FFF",
  },
  buttonBack: {
    width: "130px",
    height: "50px",
  },
  buttonCancel: {
    width: "130px",
    height: "50px",
    background: "white",
  },
  buttonForm: {
    height: "50px",
    background: theme.themeColor,
    color: "white",
    marginBottom: "20px",
  },
  //
  appBar: {
    background: "transparent",
    color: "#000",
    border: "none",
  },
  //   containers
  generalContent: {
    background: "white",
    borderRadius: "12px",
    padding: "2em",
  },
  detailContent: {
    background: "white",
    borderRadius: "12px",
    padding: "0em 2em",
    marginLeft: "1rem",
    height: "56vh",
  },
  tableContent: {
    height: 650,
    width: "100%",
  },
  //

  boxMedical: {
    height: "80px",
    borderRadius: "15px",
    background: theme.themeColorSoft,
    marginTop: "10px",
    width: "100%",
    marginBottom: "10px",
  },
  title: {
    lineHeight: "5px",
    fontSize: "17px",
    color: "black",
  },
  accordion: {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    padding: "0",
  },
  text: {
    fontSize: "12px",
    marginTop: 10,
    color: "black",
  },
  circlePhoto: {
    background: theme.themeColorSoft,
    borderRadius: "100px",
    width: "65px",
    height: "65px",
  },
  miniBox: {
    background: "#F0F0F0",
    borderRadius: "8px",
    width: "130px",
    height: "50px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  miniBoxProductsFilter: {
    background: "#F0F0F0",
    borderRadius: "30px",
    marginRight: "10px",
    padding: "10px 30px 10px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  miniBoxProductsFilterSelected: {
    background: theme.themeColorSoft,
    borderRadius: "30px",
    marginRight: "10px",
    padding: "10px 30px 10px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxFinish: {
    background: theme.themeColorSoft,
    borderRadius: "8px",
    width: "110px",
    height: "50px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  miniBoxSelected: {
    background: theme.themeColorSoft,
    borderRadius: "8px",
    width: "130px",
    height: "50px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  miniBoxNutrition: {
    background: theme.themeColorSoft,
    borderRadius: "8px",
    width: "110px",
    height: "50px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "240px",
  },

  itemFood: {
    height: "48px",
    borderRadius: "8px",
    border: "1px solid #f3f3f3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    marginBottom: "10px",
  },
  colorButton: {
    background: theme.themeColorSoft,
  },
  containerImageTrainer: {
    marginTop: "40px",
    height: "149px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    borderRadius: "20px",
    padding: "20px 40px",
    background: theme.themeColorSoft,
  },
  buttonConfig: {
    width: "170px",
    height: "150px",
    background: "white",
    borderRadius: "20px",
  },
  containerChart: {
    padding: "30px",
    height: "320px",
    borderRadius: "20px",
    background: "white",
  },
  imageContainer: {
    position: "absolute",
    top: 30,
    left: 10,
  },
  customAvatar: {
    width: "105px",
    height: "105px",
    borderRadius: "50%",
    background: theme.themeColorSoft,
  },
  itemSesion: {
    userSelect: "none",
    padding: "20px 10px",
    width: "70%",
    borderRadius: "15px",
    background: "white",
    marginBottom: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  itemHistoryActivity: {
    borderRadius: "12px",
    background: "#F3F3F3",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  itemHistoryActivitySuggestion: {
    width: "250px",
    borderRadius: "12px",
    background: "#F3F3F3",
    padding: "20px",
  },
  itemData: {
    borderRadius: "12px",
    background: "#F3F3F3",
    padding: "20px 50px",
    width: "400px",
    margin: "20px",
  },
  itemDataObservation: {
    borderRadius: "12px",
    background: "#F3F3F3",
    padding: "20px 50px",
    width: "250px",
    marginLeft: "50px",
    marginTop: "20px",
    minHeight: "255px",
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "10px",
    background: "#f3f3f3",
    marginLeft: "10px",
  },
  buttonQuote: {
    width: "88px",
    height: "35px",
    borderRadius: "10px",
    background: "#f3f3f3",
  },
  cardItemSession: {
    height: "190px",
    borderRadius: "8px",
    background: "#f3f3f3",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "15px",
  },
  fontGray: {
    color: "rgba(60, 60, 60, .5)",
  },
  box: {
    background: "#FFF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxButton: {
    background: theme.themeColorSoft,
    height: 60,
    paddingLeft: 10,
    paddingRight: 10,
    width: 180,
  },
  boxButtonTwo: {
    background: theme.themeColorSoft,
    height: 60,
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
  },
  itemNutrition: {
    borderRadius: 8,
    background: "#F3F3F3",
    padding: 10,
  },
  textBreak: {
    wordWrap: "break-word",
  },
  newBox: {
    height: "35px",
    background: "#f3f3f3",
    borderRadius: "10px",
    display: "flex",
    padding: "5px",
    alignItems: "center",
    justifyContent: "space-around",
    marginLeft: "50px",
  },
  iconBox: {
    height: "30px",
    background: "#f3f3f3",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconBoxForm: {
    height: "48px",
    width: "120px",
    background: "#f3f3f3",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accordionShadow: {
    border: "1px solid #F9F9F9",
    boxShadow: "0px 10px 15px rgba(145, 158, 171, 0.05)",
    borderRadius: 15,
  },
  boxObservation: {
    borderRadius: "10px",
    background: "#f3f3f3",
    padding: "5px",
    minHeight: "172px",
    minWidth: "269px",
  },
  boxObservationTwo: {
    padding: "20px",
    borderRadius: "10px",
    background: "#f3f3f3",
  },
  fontObservation: {
    marginTop: "10px",
    marginBottom: "7px",
    fontSize: "11px",
    color: "rgba(0,0,0,.4)",
  },
  fotnDayCalendar: {
    fontSize: "13px",
    marginLeft: "2px",
  },
  fontDayCalendar: {
    fontSize: "13px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
  cellDayCalendar: {
    textAlign: "center",
    borderLeft: "1px solid #f3f3f3",
  },
  cellDayCalendarDisabled: {
    textAlign: "center",
    borderLeft: "1px solid #f3f3f3",
    backgroundColor: "#f3f3f3",
    opacity: 0.7,
    pointerEvents: "none",
    overflow: "hidden",
    padding: "0px!important",
  },
  cellHourCalendar: {
    textAlign: "center",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    // borderRight: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: "#f3f3f3",
    opacity: 0.7,
    overflow: "hidden",
    padding: "0px!important",
  },
  placeRound: {
    background: "#F3F3F3",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: 46,
    padding: 10,
  },
  roudnAfiliateCount: {
    background: "#F3F3F3",
    borderRadius: 10,
    margin: "10px 5px",
    padding: 10,
  },
  boxItem: {
    width: "100px",
    borderRadius: "10px",
    background: theme.themeColorSoft,
    textAlign: "center",
  },
  cardList: {
    padding: "10px",
    borderRadius: "10px",
    background: "#f3f3f3",
    marginTop: "8px",
  },

  miniBoxAnnexes: {
    width: "140px",
    height: "35px",
    background: "white",
    borderRadius: "8px",
    border: "1px solid #cccccc",
    color: "#cccccc",
    marginRight: "5px",
  },
  miniBoxAnnexesSelected: {
    width: "138px",
    height: "35px",
    background: theme.themeColorSoft,
    borderRadius: "8px",
    border: "1px solid #cccccc",
    marginRight: "5px",
  },
  minButton: {
    color: "white",
    width: "87px",
    height: "35px",
    borderRadius: "8px",
    fontSize: "10px",
    background: theme.themeColor,
  },
  avatarNutrition: {
    background: "transparent",
  },
  buttonSchedule: {
    minWidth: "46px !important",
    minHeight: "50px",
    borderRadius: "10px",
    background: "rgba(60, 60, 59, .3)",
    color: "black",
    margin: "3px",
  },
  buttonScheduleSelected: {
    minWidth: "46px !important",
    minHeight: "50px",
    borderRadius: "10px",
    background: "rgba(60, 60, 59, 1)",
    color: "white !important",
    margin: "3px",
  },
  quoteAvailable: {
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      background: "#8a8a8a",
    },
  },
  badgeCalendar: {
    borderRadius: "50%",
  },
  buttonAdd: {
    width: "48px",
    height: "48px",
    background: "#CCCCCC",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  },
  cardSchedule: {
    width: "230px",
    cursor: "pointer",
    height: "48px",
    backgroundColor: "#F3F3F3",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    marginBottom: "5px",
    marginRight: "10px",
  },
  fontCardSchedule: {
    fontWeight: "bold",
  },
  boldText: {
    fontWeight: "bold",
  },
  buttonDayCalendar: {
    maxHeight: "60px",
    borderRadius: "10px",
    padding: "20px 10px",
    marginRight: 5,
    color: theme.palette.black,
    backgroundColor: "rgba(60, 60, 59, 0.1)",
  },
  buttonDayCalendarWithCollaborator: {
    width: "50px",
    maxHeight: "70px",
    borderRadius: "10px",
    padding: "20px 10px",
    marginRight: 5,
    color: theme.palette.black,
    backgroundColor: "rgba(60, 60, 59, 0.1)",
  },
  buttonDayCalendarSelect: {
    backgroundColor: "rgba(60, 60, 59, 1)!important",
    color: "#ffffff!important",
  },
  buttonDayCalendarWithDuration: {
    backgroundColor: "rgba(60, 60, 59, 0.4)",
    color: "#ffffff",
  },
  buttonClearHour: {
    backgroundColor: "none",
    color: "#000",
    padding: "6px",
    borderRadius: "6px",
  },
  buttonCreateRangeSchedule: {
    maxHeight: "50px",
    borderRadius: "10px",
    padding: "20px 10px",
    marginRight: 5,
    color: theme.palette.black,
    backgroundColor: "rgba(60, 60, 59, 0.1)",
  },
  buttonNews: {
    borderRadius: "10px",
    padding: "15px!important",
    marginRight: 5,
    color: theme.palette.black,
    backgroundColor: "rgba(60, 60, 59, 0.1)",
  },
  buttonCalendar: {
    borderRadius: "10px",
    padding: "15px!important",
    background: "none",
    color: "#3C3C3B",
  },
  buttonItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 2px",
    padding: 10,
    borderRadius: 10,
  },
  textBold: {
    fontWeight: "bold",
  },
  boxRisk: {
    width: "94px",
    height: "38px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
  },
  boxNormal: {
    width: "94px",
    height: "38px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.themeColorSoft,
    borderRadius: "8px",
  },
  boxAppointment: {
    height: "47px",
    minWidth: "60px",
    borderRadius: "10px",
    background: "#4959A2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  paginationStyle: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  thumb: {
    borderRadius: "50%",
    marginBottom: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  thumb2: {
    borderRadius: "50%",
    marginBottom: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    marginTop: "5px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    objectFit: "cover",
  },
  img2: {
    marginTop: "5px",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    objectFit: "cover",
  },
  avatarImage: {
    width: 60,
    height: 60,
    marginTop: 5,
    borderRadius: "50%",
    background: "#C4C4C4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonIcon: {
    backgroundColor: "#F3F3F3",
    borderRadius: 10,
  },
  containerIconButtonHome: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    height: "100%",
  },
  modalDialog: {
    padding: "10px",
  },
  containerCollaborator: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
  },
  buttonCollaborator: {
    width: "100%",
    cursor: "pointer",
    userSelect: "none",
  },
  cardCollaboratorList: {
    padding: "15px 10px",
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    userSelect: "none",
    cursor: "pointer",
  },
  buttonListIngredients: {
    borderRadius: "10px",
    marginTop: "2px",
    color: "black",
    background: theme.themeColorSoft,
    height: "55px",
  },
  boxGoalRecipe: {
    width: 276,
    height: 35,
    background: "transparent",
    border: `1px solid #94C97A`,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxPreparation: {
    minHeight: "95%",
    minWidth: "388px",
    background: "#F3F3F3",
    borderRadius: "12px",
    padding: "35px",
  },
  itemFoodList: {
    marginBottom: "10px",
    paddingLeft: "10px",
    height: "48px",
    boxShadow: "0px 4px 15px rgba(145, 158, 171, 0.15)",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cardDetailCollaborator: {
    background: "#ffffff",
    padding: "20px",
    borderRadius: "20px",
  },
  textWrap: {
    wordWrap: "break-word",
  },
  cardRecipes: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 15px rgba(145, 158, 171, 0.15)",
    borderRadius: "10px",
    cursor: "pointer",
  },
  cardSuggestions: {
    margin: "10px 0px 20px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px 10px 20px",
    background: "rgba(60, 60, 59, .10)",
    borderRadius: "12px",
    cursor: "pointer",
  },
  iconButtonArrow: {
    width: "35px",
    height: "35px",
    borderRadius: "10px",
    background: "#F3F3F3",
  },
  buttonBlock: {
    marginRight: "10px",
    height: "50px",
    background: "transparent",
    color: "#3C3C3B",
  },
  buttonReasing: {
    marginRight: "10px",
    border: `2px solid ${theme.palette.primary.main}`,
    background: "#FFFFFF",
    color: "#3C3C3B",
    height: "50px",
  },
  buttonSave: {
    marginRight: "10px",
    background: theme.palette.primary.main,
    color: "#ffff",
    height: "50px",
  },
  containerRepetitions: {
    background: "#F3F3F3",
    borderRadius: 10,
    height: 50,
    display: "flex",
    alignItems: "center",
    padding: 10,
    justifyContent: "space-around",
    marginBottom: "30px",
  },
  comercialBoxContainer: {
    background: theme.themeColorSoft,
    borderRadius: 10,
    padding: 20,
    display: "flex",
    justifyContent: "center",
  },
  quotationsBoxTitle: {
    padding: 10,
    display: "flex",
    justifyContent: "center",
    borderRight: "1px solid rgba(0,0,0, .1)",
  },
  defaultBoxCenteredContainer: {
    background: "#F3F3F3",
    borderRadius: 10,
    padding: 10,
    display: "flex",
    justifyContent: "center",
  },
  defaultBoxCenteredContainerQuotations: {
    background: theme.themeColorSoft,
    borderRadius: 10,
    padding: 10,
    display: "flex",
    justifyContent: "center",
  },
  emptyPriceBox: {
    border: `1px solid ${theme.themeColor}`,
    color: theme.themeColor,
    borderRadius: 10,
    padding: 10,
    display: "flex",
    justifyContent: "center",
  },
  defaultBoxContainer: {
    background: "#F3F3F3",
    borderRadius: 20,
    padding: 20,
  },
  defaultBoxContainerAddPrice: {
    background: "#F3F3F3",
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  LoaderText: {
    width: 100,
    height: 20,
    backgroundColor: "rgba(0, 0, 0, .1)",
    borderRadius: 20,
  },
  defaultBoxContainerPrices: {
    background: "#F3F3F3",
    borderRadius: 10,
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },

  defaultBoxContainerAddCurrencies: {
    background: theme.themeColorSoft,
    borderRadius: 20,
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  rightPanelKit: {
    background: "#F3F3F3",
    borderRadius: "20px",
    padding: "20px",
  },
  cardItemProu: {
    borderRadius: "10px",
    background: "#FF6978",
  },
  //404
  boxContainError: {
    background: "#E3E3E3",
    height: "100%",
    borderRadius: "20px",
    color: "#FFFFFF",
    margin: "auto 10px",
  },
  buttonStart: {
    width: "182px",
    height: "50px",
    border: "none",
    borderRadius: "15px",
    background: "#DD702B",
    color: "white",
  },
  boxImage: {
    position: "absolute",
    width: "160px",
    height: "160px",
  },
  boxNumber1: {
    marginLeft: "20px",
    fontSize: "280px",
  },
  boxNumber2: {
    marginLeft: "100px",
    marginRight: "45px",
    fontSize: "280px",
  },
  boxText2: {
    fontSize: "120px",
    lineHeight: "100px",
  },
  boxTextp: {
    color: "#000",
    fontSize: "16px",
    lineHeight: "60px",
  },
  //404
  // a style rule
}));

export { useStyles };
