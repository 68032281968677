import React from 'react';
import { Route } from 'react-router-dom'

import LoginPage from 'pages/LoginPage/LoginPage';

/**
 * Page container for the auth forms
 */
const AuthContainer = () => {
    return (
        <main>
            <Route exact path="/" component={LoginPage} />
        </main>
    );
};

export default AuthContainer;