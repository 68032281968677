import React from "react";

export const ConfigNameRoutes = {
  listCashAnalyze: "/list-all-cash-day",
  listCashAnalyzeDetail: "/list-all-cash-day/:key",
};

export const ConfigRootConfig = [
  {
    path: "/",
    component: React.lazy(() =>
      import("pages/ListCashAnalyze/ListCashAnalyze")
    ),
  },
  {
    path: "/list-all-cash-day",
    component: React.lazy(() =>
      import("pages/ListCashAnalyze/ListCashAnalyze")
    ),
  },
  {
    path: "/detail-business/:id",
    component: React.lazy(() => import("pages/DetailBusiness/DetailBusiness")),
  },
  {
    path: "/detail-business/:id?idBranch=:value",
    component: React.lazy(() => import("pages/DetailBusiness/DetailBusiness")),
  },
  {
    path: "/detail-course/:id",
    component: React.lazy(() => import("pages/CoursesPage/DetailCourse/DetailCourse")),
  },
  {
    path: "/countries",
    component: React.lazy(() => import("pages/CountriesPage/MasterCountries")),
  },
  {
    path: "/cities",
    component: React.lazy(() => import("pages/CitiesPage/MasterCities")),
  },
  {
    path: "/source",
    component: React.lazy(() => import("pages/SourcePage/MasterSource")),
  },
  {
    path: "/courses",
    component: React.lazy(() => import("pages/CoursesPage/CoursesPage")),
  },
  {
    path: "/wallet",
    component: React.lazy(() => import("pages/WalletPage/WalletPage")),
  },
];
