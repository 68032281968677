import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 10,
      },
      editorContainer: {
        boxShadow:
          "0px 0px 2px rgba(52,58,67,0.10), 0px 1px 2px rgba(52,58,67,0.08), 0px 1px 4px rgba(52,58,67,0.08)",
        borderRadius: "8px",
        padding: "11.5px 14px",
      },
      hidePlaceholder: {
        display: "block !important",
      },
    },
    MuiPaper: {
      rounded: {
        border: "none",
        boxShadow: "0px 0px 1px rgba(52,58,67,0.75)",
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundImage: "linear-gradient(85deg, #48AC98 0%, #45C4AA 100%)",
        "&.Mui-disabled": {
          backgroundImage: "none",
        },
      },
    },
    MuiFormLabel: {
      root: {
        transition: "color 150ms cubic-bezier(0.4, 0, 1, 1)",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "8px",
      },
      root: {
        width: "100%",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: "9px 0 0 0 !important",
      },
      endAdornment: {
        top: "calc(50% - 9px)",
      },
    },
    WAMuiChipInput: {
      inputRoot: {
        padding: "0 14px !important",
        boxShadow:
          "0px 0px 2px rgba(52,58,67,0.10), 0px 1px 2px rgba(52,58,67,0.08), 0px 1px 4px rgba(52,58,67,0.08)",
      },
      chip: {
        marginTop: "8px !important",
      },
      input: {
        boxShadow: "none !important",
        padding: "11.5px 14px !important",
        paddingLeft: "0 !important",
        height: "17px !important",
        marginTop: "0",
      },
    },
    MuiOutlinedInput: {
      root: {
        // boxShadow: '0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08)',
        "label + &": {
          marginTop: "0.5rem",
        },
        "&:focus": {
          boxShadow:
            "0px 0px 2px rgba(52, 58, 67, 0.1), 0px 2px 5px rgba(52, 58, 67, 0.08), 0px 5px 15px rgba(52, 58, 67, 0.08)",
        },
      },
      adornedStart: {
        paddingLeft: "0px !important",
      },
      input: {
        padding: "11.5px 14px !important",
        borderRadius: 8,
        position: "relative",
        boxShadow:
          "0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08)",

        transition: "box-shadow 150ms cubic-bezier(0.4, 0, 1, 1)",
        "&:focus": {
          boxShadow:
            "0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08)",
        },
      },
      notchedOutline: {
        display: "none",
      },
      multiline: {
        padding: "0",
        textarea: {
          padding: "11.5px 14px",
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: "700",
        "&:focus": {
          outlineColor: "#266678",
        },
      },
    },
  },
  palette: {
    type: "light",
    secondary: {
      light: "#5c8c9c",
      main: "#266678",
      dark: "#1b4754",
    },
    error: {
      light: "#d49494",
      main: "#cb7c7a",
      dark: "#a26362",
    },
    warning: {
      light: "#dab47d",
      main: "#cda35f",
      dark: "#b99356",
    },
    success: {
      light: "#73baa9",
      main: "#48ac98",
      dark: "#3a8a7a",
    },
    text: {
      primary: "#0f2930",
      secondary: "#53627c",
      disabled: "#a6aebc",
      hint: "#a6aebc",
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
  },
  shadows: [
    "none",
    "0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 10px 20px rgba(52,58,67,0.08),0px 20px 40px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
    "0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)",
  ],
  typography: {
    fontFamily: [
      '"Bariol"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: "700",
    },
    h2: {
      fontWeight: "700",
    },
    h3: {
      fontWeight: "700",
    },
    h4: {
      fontWeight: "700",
    },
    h5: {
      fontWeight: "700",
    },
    h6: {
      fontWeight: "700",
    },
    button: {
      textTransform: "none",
      fontSize: "1.125rem", // 18px
      fontWeight: "100",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

export default theme;
