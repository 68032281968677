import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal dependencies
import { AppContainer, AuthContainer } from './containers';

function App({ isUserAuthenticated }) {
  const ComponentToRender = isUserAuthenticated ? AppContainer : AuthContainer;

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <ComponentToRender />
    </div>
  );

}

const mapStateToProps = ({ auth }) => ({
  isUserAuthenticated: auth.isUserAuthenticated
});


export default connect(
  mapStateToProps
)(App);
