import axios from "axios";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
const getLocalToken = () => localStorage.getItem("token") || "";
const currentUser = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiV2VpYm9vay5jbyBNYW5hZ2VyICIsImVtYWlsIjoiYXBwQHdlaWJvb2suY28iLCJzdGF0dXMiOnRydWUsImlkX2JyYW5jaCI6bnVsbCwiaWRfYnVzaW5lc3MiOiI1ZDY1NjdjNWZhYTJmMGJhY2VkZWFhM2UiLCJrZXkiOiJkZXZfcWFfdHdvIiwiaWF0IjoxNjU2NDY3MjYzfQ.mjKTNZ8w3gw2t6LHLtoCM84-IjvDjJ10AR0jlpSPa0g";


export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
});

export const axiosPaymentInstance = axios.create({
  baseURL: "https://wallet.weibook.co/v1",
  headers: {
    authorization: `${getLocalToken()}`,
  },
});

export const axiosInstanceWithKey = (bsKey = "", zone = "") => axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
  headers: {
    key: bsKey,
    authorization: `${currentUser}`,
    app_source: "private",
    zone: zone,
  },
});


export const axiosWallet = (bsKey = "") => axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_WALLET,
  headers: {
    key: bsKey,
    authorization: `${getLocalToken()}`,
    app_source: "private",
  },
});

export const axiosPrivateWithKey = (bsKey = "", zone = "") => axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_MBA,
  headers: {
    key: bsKey,
    authorization: `${getLocalToken()}`,
    app_source: "private",
    zone: zone,
  },
});

export const axiosCourses = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_WEIACADEMY,
  headers: {
    authorization: `${currentUser}`,
    app_source: "private",
    key: "app_wei",
    rolename: "collapp",
    zone: "America / Bogota",
    version_app: "2",
    "Accept-Language": "en",
    version_biz_market: "3.3",
    device_type: "iOS",
    idcollaborator: "65428dc5ac59fa0011508a56",
    serial_device: "3820ED4D-A47D-45D9-84DD-83F00677905E",
    branch: null,
  }
});


export const axiosPrivateInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_MBA,
});

export const axiosWalletInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_WALLET,
});


axiosInstance.interceptors.request.use(
  (config) => {
    const currentUser =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiV2VpYm9vay5jbyBNYW5hZ2VyICIsImVtYWlsIjoiYXBwQHdlaWJvb2suY28iLCJzdGF0dXMiOnRydWUsImlkX2JyYW5jaCI6bnVsbCwiaWRfYnVzaW5lc3MiOiI1ZDY1NjdjNWZhYTJmMGJhY2VkZWFhM2UiLCJrZXkiOiJkZXZfcWFfdHdvIiwiaWF0IjoxNjU2NDY3MjYzfQ.mjKTNZ8w3gw2t6LHLtoCM84-IjvDjJ10AR0jlpSPa0g";
    if (currentUser) {
      config.headers["authorization"] = `${currentUser}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosWalletInstance.interceptors.request.use(
  (config) => {
    const currentUser = localStorage.getItem("token") || "";
    if (currentUser) {
      config.headers["authorization"] = `${currentUser}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosPrivateInstance.interceptors.request.use(
  (config) => {
    const currentUser = localStorage.getItem("token") || "";
    if (currentUser) {
      config.headers["authorization"] = `${currentUser}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


axiosPrivateInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      cookies.remove("user", { path: "/" });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      window.location.assign("/");
      return;
    }
    return error;
  }
);
