import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';


//UI
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';


import { logIn } from 'modules/auth';
import { emailRegex } from 'utils/misc';

/**
 * Log in form component
 * @param {function} logIn log in function from the store
 * @param {boolean} isLoggingIn log in loader variable from the store
 * @param {function} handleLogin function to execute on login success
 */
const SignInForm = ({ logIn, isLoggingIn, onLogin }) => {
    const { t } = useTranslation();
    const [loadingLoginForm, setLoadingLoginForm] = useState(false);
    const [messageError, setMessageError] = useState('');
    const { handleSubmit, control, formState: { errors } } = useForm();
    const { enqueueSnackbar } = useSnackbar();


    const handleLoginError = () =>{
        enqueueSnackbar('Usuario o contraseña no validas', {
            variant: 'error',
            autoHideDuration: 2500
        })
        setLoadingLoginForm(false);
    };

    const onSubmit = (data) => {
        setLoadingLoginForm(true);
        logIn(data.email, data.password, onLogin, handleLoginError)
    };

    return (
        <Card elevation={0} style={{ margin: 20 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    >
                        <Controller
                            render={({ field }) =>
                                <TextField {...field}
                                    id="outlined-email"
                                    label={t('LogIn.email')}
                                    variant="outlined" />
                            }
                            control={control}
                            defaultValue=""
                            error={errors.email ? true : false}
                            name="email"
                            rules={{ required: true, pattern: emailRegex }}
                        />
                        <FormHelperText error={errors.email ? true : false}>{errors.email ? t('LogIn.emailError') : ''}</FormHelperText>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    >
                        <Controller
                            render={({ field }) =>
                                <TextField {...field}
                                    id="outlined-password"
                                    label={t('LogIn.password')}
                                    type="password"
                                    variant="outlined" />
                            }
                            control={control}
                            defaultValue=""
                            error={errors.password ? true : false}
                            name="password"
                            rules={{ required: true }}
                        />
                        <FormHelperText error={errors.password ? true : false}>{errors.password ? t('LogIn.passwordError') : ''}</FormHelperText>
                    </FormControl>
                    {messageError && <Alert severity="error">{messageError}</Alert>}
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                    <Button
                        disabled={isLoggingIn}
                        type="submit"
                        variant="contained">
                        {loadingLoginForm ?
                            <CircularProgress
                                size={30}
                                color="secondary" /> :
                            t('LogIn.Btn')
                        }
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn
});

const mapDispatchToProps = dispatch => bindActionCreators({ logIn }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInForm);
