import React, { useState, useEffect } from "react";

// translate
import { useTranslation } from "react-i18next";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// route
import { useHistory, Route, Link as RouterLink } from "react-router-dom";
import { ConfigNameRoutes, ConfigRootConfig } from "utils/routes";

// external dependencies
import clsx from "clsx";

// modules
import { logOut } from "modules/auth";

// app container
import {
  drawerStyles,
  TopBar,
  ContentItems,
  FooterBar,
  Actions,
} from "./AppContainer.styles";

// internal dependencies
import AvatarUser from "components/Shared/AvatarUser/AvatarUser";

// components
import Loading from "components/Shared/Loading/Loading";

// ui
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ReorderIcon from "@mui/icons-material/Reorder";
import UpcomingIcon from '@mui/icons-material/Upcoming';
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import SchoolIcon from '@mui/icons-material/School';
import { useMediaQuery } from "@mui/material";

// menu options
const optionsMenu = [
  {
    id: 1,
    name: "Monitoreo de Caja",
    icon: <ReorderIcon />,
    route: "/list-all-cash-day",
  },
  { id: 2, name: "Paises", icon: <PublicIcon />, route: "/countries" },
  {
    id: 3,
    name: "Ciudades",
    icon: <LocationCityIcon />,
    route: "/cities",
  },
  {
    id: 4,
    name: "Activaciones",
    icon: <UpcomingIcon />,
    route: "/source",
  },
  {
    id: 5,
    name: "Wallet",
    icon: <AccountBalanceIcon />,
    route: "/wallet",
  },
  // {
  //   id: 5,
  //   name: "Cursos",
  //   icon: <SchoolIcon />,
  //   route: "/courses",
  // },
];

const AppContainer = ({ userEmail, userType, logOut }) => {
  // constans
  const classes = drawerStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:640px)');


  // state variables
  const [openDrawerPrimary, setOpenDrawerPrimary] = useState(true);
  const [anchorEl, setanchorEl] = useState(null);

  // menu user
  const handleUserMenuClick = (event) => setanchorEl(event.currentTarget);
  const handleClose = () => setanchorEl(null);

  const currentPathName =
    history.location.pathname.slice(
      0,
      history.location.pathname.lastIndexOf("/")
    ) || history.location.pathname;

  // go to
  const goPageMenuBar = (value) => {
    switch (value) {
      case "config":
        history.push(ConfigNameRoutes.config);
        break;

      case "help":
        break;

      default:
        break;
    }
  };

  // logout
  const logoutAndGoHome = () => {
    logOut();
    history.push("/");
  };

  const toggleDrawer = () => {
    setOpenDrawerPrimary(!openDrawerPrimary);
  };

  useEffect(() => {
    toggleDrawer();
  }, [userType]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        elevation={0}
        className={matches ? "" :
          clsx(classes.appBar, {
            [classes.appBarShift]: openDrawerPrimary,
          })
        }
        style={matches ? {} : {
          paddingLeft: openDrawerPrimary ? 0 : "100px",
          paddingBottom: 0,
          paddingTop: 0,
          borderRight: "none !important",
        }}
      >
        <TopBar color="transparent" style={{ justifyContent: "space-between" }}>
          <div
            className={clsx(classes.headerActions)}
            style={matches ? {} : { marginLeft: openDrawerPrimary ? 250 : 0 }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              style={{ marginRight: 16 }}
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              {t("Home.Title")}
            </Typography>
          </div>

          <Actions>
            <AvatarUser
              handleUserMenuClick={handleUserMenuClick}
              anchorEl={anchorEl}
              userEmail={userEmail}
            />

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              style={{ top: 15 }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => goPageMenuBar("help")}>
                {t("Menu.help")}
              </MenuItem>
              <MenuItem onClick={logoutAndGoHome}>{t("Logout")}</MenuItem>
            </Menu>
          </Actions>
        </TopBar>
      </AppBar>
      <Drawer
        variant={matches ? "temporary" : "permanent"}
        anchor="left"
        open={openDrawerPrimary}
        onClose={() => setOpenDrawerPrimary(false)}
        className={clsx(classes.openDrawerPrimary, {
          [classes.drawerOpen]: openDrawerPrimary,
          [classes.drawerClose]: !openDrawerPrimary,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawerPrimary,
            [classes.drawerClose]: !openDrawerPrimary,
          }),
        }}

      >
        <div className={classes.drawerHeader}>
          <img
            style={{ height: 50, width: !openDrawerPrimary ? 50 : 140 }}
            src={
              openDrawerPrimary
                ? "https://d1itoeljuz09pk.cloudfront.net/recursos/logo.svg"
                : "https://weibook-public.s3.amazonaws.com/recursos/w.svg"
            }
          />
        </div>

        <ContentItems style={{ overflowX: "hidden" }}>
          <List>
            {optionsMenu.map(({ name, id, icon, route }) => (
              <ListItem
                style={{
                  height: "3em",
                }}
                key={id}
                button
                component={RouterLink}
                to={route}
              >
                <Tooltip
                  title={openDrawerPrimary ? "" : name}
                  placement="right"
                >
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: openDrawerPrimary ? "" : "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: openDrawerPrimary ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={name}
                />
              </ListItem>
            ))}
          </List>
        </ContentItems>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawerPrimary,
        })}
      >
        <main className={classes.container}>
          <div className={`container`}>
            <React.Suspense fallback={<Loading />}>
              {ConfigRootConfig.map((route) => (
                <Route
                  exact={route.path === "/" ? true : false}
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </React.Suspense>
          </div>
        </main>
      </main>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  isLoggingOut: auth.isUserAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logOut,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
