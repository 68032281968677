import { axiosInstance } from "./instance";

const loginService = (user, pass) => {
  return new Promise((resolve, reject) => {
    //URL Web
    axiosInstance
      .post("/auth-private-admin", {
        email: user,
        password: pass,
      })
      .then(({ data }) => {
        if (data && data.token) {
          resolve({ data });
        } else {
          reject("Usuario o contraseña inválida");
        }
      })
      .catch((error) => {
        reject("Usuario o contraseña inválida");
      });
  });
};

const signUpService = () => {};

const logoutService = () => {};

export { loginService, signUpService, logoutService };
